
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import Article from '@/types/Article';

    import ArticleHero from '@/components/ArticleHero.vue';
    import ArticleFooterLinks from '@/components/ArticleFooterLinks.vue';
    import SocialShare from '@/components/SocialShare.vue';

    const relatedArticles = ['geld-in-wertpapieren-anlegen', 'zahlen-sie-bar-oder-mit-karte'];

    @Component({
        components: {
            ArticleHero,
            ArticleFooterLinks,
            SocialShare,
        },
    })
    export default class Spartypen extends Vue {
        get url() {
            return window.location.href;
        }
    }
