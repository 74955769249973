
    import { Component, Vue } from 'vue-property-decorator';

    import Spartypen from '@/components/Spartypen.vue';

    @Component({
        components: {
            Spartypen,
        },
        metaInfo: {
            title: 'Verschiedene Spartypen',
        },
    })
    export default class SpartypenView extends Vue {}
